import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NetfirmLoginComponent } from './netfirm-login/netfirm-login.component';
import {
	defineSafButton,
	defineSafCard,
	defineSafDivider,
	defineSafForm,
	defineSafIcon,
	defineSafLink,
	defineSafModal,
} from '@saffron/web-components';
import { CookieService } from 'ngx-cookie-service';
import { NetfirmModalComponent } from './shared/components/netfirm-modal/netfirm-modal.component';
import { NetfirmCardComponent } from './shared/components/netfirm-card/netfirm-card.component';
import { NetfirmTimeoutComponent } from './components/netfirm-timeout/netfirm-timeout.component';
import { NetfirmSystemdownComponent } from './components/netfirm-systemdown/netfirm-systemdown.component';
import { NetfirmBannerComponent } from './shared/netfirm-banner/netfirm-banner.component';
import { NetfirmLoginLicenseInactiveComponent } from './components/netfirm-login-license-inactive/netfirm-login-license-inactive.component';
import { NetfirmClientStaffComponent } from './components/netfirm-client-staff/netfirm-client-staff.component';
import { NetfirmUnableLoginComponent } from './components/netfirm-unable-login/netfirm-unable-login.component';
import { NetfirmSignBackInPageComponent } from './components/netfirm-sign-back-in-page/netfirm-sign-back-in-page.component';
import { NetfirmUnknownLoginFailureComponent } from './components/netfirm-unknown-login-failure/netfirm-unknown-login-failure.component';
import { NetfirmMaintenanceModeComponent } from './components/netfirm-maintenance-mode/netfirm-maintenance-mode.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
	return new TranslateHttpLoader(http, '/nextgen/assets/i18n/', '.json');
}

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		AppComponent,
		NetfirmLoginComponent,
		NetfirmModalComponent,
		NetfirmCardComponent,
		NetfirmTimeoutComponent,
		NetfirmSystemdownComponent,
		NetfirmBannerComponent,
		NetfirmLoginLicenseInactiveComponent,
		NetfirmClientStaffComponent,
		NetfirmUnableLoginComponent,
		NetfirmSignBackInPageComponent,
		NetfirmUnknownLoginFailureComponent,
		NetfirmMaintenanceModeComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
	],
	providers: [CookieService],
	bootstrap: [AppComponent],
})
export class AppModule {}

defineSafButton();
defineSafForm();
defineSafCard();
defineSafLink();
defineSafIcon();
defineSafDivider();
defineSafModal();
