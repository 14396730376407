import { Component } from '@angular/core';
import { NetfirmLoginConstants } from './models/netfirm-login-constants.model';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'netfirm-login-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	public title = 'NetfirmLogin';

	public constructor(private readonly translate: TranslateService) {
		const url = window.location.href;
		const hash = window.location.hash;
		const isInternal = NetfirmLoginConstants.internalRoutes
			.map(routeURL => hash.includes(routeURL))
			.reduce((prev, next) => prev || next);
		if (hash.length > 2 && !isInternal) {
			document.cookie = `dashboardDeepLink=${url};expires=Session;path=/`;
		}

		const browserLang = this.translate.getBrowserLang();
		let locale;
		// TODO: need to add logic to exchange the language based on the cookie generated by BE when user change
		// the language in the profile
		switch (browserLang) {
			case 'en':
				locale = 'en-US';
				break;
			case 'fr':
				locale = 'fr-CA';
				break;
			default:
				locale = 'en-US';
		}

		this.translate.setDefaultLang(locale);
	}
}
