<saf-card>
	<div class="NetfirmLogin-form--content" slot="content">
		<div class="NetfirmLogin-form--container">
			<div slot="header">
				<ng-content select="[slot=header]"></ng-content>
			</div>
			<div slot="content">
				<ng-content select="[slot=body]"></ng-content>
				<ng-content select="[slot=firm-info]"></ng-content>
				<ng-container *ngIf="switchButtons; then signInWithTRTemplate"></ng-container>
				<ng-container *ngIf="switchButtons; then dividerTemplate"></ng-container>
				<ng-content select="[slot=form-fields]"></ng-content>
			</div>
			<div>
				<div>
					<div class="NetfirmLogin-Account" *ngIf="switchButtons && showTextEnhancements && isRootPathC">
						<p>{{ 'Netfirm.CiamLogin.CreateOrUpdateTRAccount' | translate }}</p>
					</div>
					<saf-button
						data-qe-id="button-logInToNetfirm"
						type="button"
						[variant]="primaryVariant"
						fullwidth="true"
						*ngIf="showPrimaryButton"
						[disabled]="disabledPrimaryButton"
						(click)="primaryButtonClick.emit()"
						>{{ primaryButtonText }}
					</saf-button>
				</div>
				<ng-container *ngIf="!switchButtons; then dividerTemplate"></ng-container>
				<ng-container *ngIf="!switchButtons; then signInWithTRTemplate"></ng-container>
				<ng-template #dividerTemplate>
					<div>
						<hr
							*ngIf="showOptionDivider"
							class="NetfirmLogin-optionDivider"
							[attr.data-content]="'Netfirm.CiamLogin.Or' | translate | uppercase"
						/>
					</div>
				</ng-template>
				<ng-template #signInWithTRTemplate>
					<div class="NetfirmLogin-already" *ngIf="!switchButtons && showTextEnhancements">
						<p>{{ 'Netfirm.CiamLogin.AlreadyUpgraded' | translate}}</p>
					</div>
					<div [ngStyle]="{ 'margin-top': switchButtons ? '20px' : '0px' }">
						<saf-button
							data-qe-id="button-SignInWithThomsonReuters"
							type="button"
							[variant]="secondaryVariant"
							fullwidth="true"
							*ngIf="showSecondaryButton"
							(click)="secondaryButtonClick.emit()"
							>{{ secondaryButtonText }}</saf-button
						>
					</div>
				</ng-template>
			</div>
			<div>
				<p *ngIf="showTooltip" class="NetfirmLogin-form--item NetfirmLogin-paragraph">
					{{labeltooltipText}}
					<saf-button aria-label="What's the difference" type="button" variant="secondary" class="NetfirmLogin-iconButton">
						<div class="tooltip">
							<span class="tooltiptext" [innerHTML]="firstLinkTooltipText"></span>
							<saf-icon
								size="16"
								icon-data="
						<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<g clip-path='url(#clip0_103_17)'>
							<path d='M7.9833 5.47833C8.53667 5.47833 8.98526 5.02974 8.98526 4.47637C8.98526 3.923 8.53667 3.47441 7.9833 3.47441C7.42993 3.47441 6.98134 3.923 6.98134 4.47637C6.98134 5.02974 7.42993 5.47833 7.9833 5.47833Z' fill='#1F1F1F'/>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M7.98328 7.48328C8.25943 7.48328 8.48328 7.70713 8.48328 7.98328V11.9911C8.48328 12.2673 8.25943 12.4911 7.98328 12.4911C7.70714 12.4911 7.48328 12.2673 7.48328 11.9911V7.98328C7.48328 7.70713 7.70714 7.48328 7.98328 7.48328Z' fill='#1F1F1F'/>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z' fill='#1F1F1F'/>
							</g>
							<defs>
							<clipPath id='clip0_103_17'>
							<rect width='16' height='16' fill='white'/>
							</clipPath>
							</defs>
							</svg>
						"
							>
							</saf-icon>
						</div>
					</saf-button>
				</p>
			</div>
			<div *ngIf="showFirstLink" class="NetfirmLogin-Login-firstLink">
				<saf-link [href]="firstLinkURL" target="_blank" id="linkNotSureWhatToDo"
					>{{ firstLinktext }}
					<saf-icon
						role="img"
						aria-label="Opens new tab"
						class="NetfirmLogin-Login-newTabIcon"
						size="16"
						icon-data="<svg alt='Opens new tab' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M15.9615 0.30908C15.9109 0.18664 15.8134 0.08911 15.6909 0.03845C15.6298 0.01318 15.5651 0 15.5 0H8.5C8.22363 0 8 0.22363 8 0.5C8 0.77637 8.22363 1 8.5 1H14.293L7.14648 8.14648C6.95117 8.34179 6.95117 8.6582 7.14648 8.85351C7.24414 8.95117 7.37208 8.99999 7.50001 8.99999C7.62794 8.99999 7.75586 8.95116 7.85352 8.85351L15 1.70703V7.5C15 7.77637 15.2236 8 15.5 8C15.7764 8 16 7.77637 16 7.5V0.5C16 0.43494 15.9868 0.37018 15.9615 0.30908Z' fill='#005DA2'/>
								<path d='M12.5 9C12.2236 9 12 9.22363 12 9.5V14C12 14.5518 11.5518 15 11 15H2C1.44824 15 1 14.5518 1 14V5C1 4.44824 1.44824 4 2 4H6.5C6.77637 4 7 3.77637 7 3.5C7 3.22363 6.77637 3 6.5 3H2C0.89746 3 0 3.89746 0 5V14C0 15.1025 0.89746 16 2 16H11C12.1025 16 13 15.1025 13 14V9.5C13 9.22363 12.7764 9 12.5 9Z' fill='#005DA2'/>
								</svg>"
					></saf-icon>
				</saf-link>
			</div>
			<div
				*ngIf="showSecondLink"
				[ngStyle]="{ 'margin-top': !showFirstLink ? '15px' :showFirstLink && showSecondLink ? '5px' : '0'}"
				class="NetfirmLogin-Login-secondLink"
			>
				<saf-link [href]="viewSystemStatusLink" target="_blank" id="linkViewSystem"
					>{{ 'Netfirm.CiamLogin.ViewSystemStatus' | translate}}
					<saf-icon
						role="img"
						aria-label="Opens new tab"
						size="16"
						class="NetfirmLogin-Login-newTabIcon"
						icon-data="<svg alt='Opens new tab' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path d='M15.9615 0.30908C15.9109 0.18664 15.8134 0.08911 15.6909 0.03845C15.6298 0.01318 15.5651 0 15.5 0H8.5C8.22363 0 8 0.22363 8 0.5C8 0.77637 8.22363 1 8.5 1H14.293L7.14648 8.14648C6.95117 8.34179 6.95117 8.6582 7.14648 8.85351C7.24414 8.95117 7.37208 8.99999 7.50001 8.99999C7.62794 8.99999 7.75586 8.95116 7.85352 8.85351L15 1.70703V7.5C15 7.77637 15.2236 8 15.5 8C15.7764 8 16 7.77637 16 7.5V0.5C16 0.43494 15.9868 0.37018 15.9615 0.30908Z' fill='#005DA2'/>
									<path d='M12.5 9C12.2236 9 12 9.22363 12 9.5V14C12 14.5518 11.5518 15 11 15H2C1.44824 15 1 14.5518 1 14V5C1 4.44824 1.44824 4 2 4H6.5C6.77637 4 7 3.77637 7 3.5C7 3.22363 6.77637 3 6.5 3H2C0.89746 3 0 3.89746 0 5V14C0 15.1025 0.89746 16 2 16H11C12.1025 16 13 15.1025 13 14V9.5C13 9.22363 12.7764 9 12.5 9Z' fill='#005DA2'/>
									</svg>"
					></saf-icon>
				</saf-link>
			</div>
			<ng-content select="[slot=form-finalText]"></ng-content>
		</div>
	</div>
</saf-card>
