export const NetfirmLoginConstants = {
	viewSystemStatusLink: 'https://taxprofessionals-status.hostedtax.thomsonreuters.com',
	notSureWhatToDoLink:
		'https://www.thomsonreuters.com/en-us/help/virtual-office-cs/account-management/determine-your-portal-type.html',
	resetPasswordError: 'Please change your password.ERROR:1',
	loginNameEmptyInputError: 'Enter a login to continue',
	loginpreNameEmptyInputError: 'Incorrect login or email. Please try again',
	emptyPasswordInputError: 'Enter your password to continue',
	emptyEmailInputError: 'Enter your email to continue',
	wrongEmailFormat: 'Use the format email@address.com.',
	invalidLoginOrPassword: 'Invalid login or password.',
	mailFormat:
		// eslint-disable-next-line
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	origScriptSessionId: '137E81ED8859D134B6829E3D626E0322',
	newAndConfirmPasswordRequired: 'New and Confirm Password fields are required.',
	passwordsMustMatch: 'New and Confirm Password fields must match.',
	passwordDoesNotMeetRequirements: 'New password does not meet requirements',
	wrongEmailFormatClient: 'Incorrect email. Please try again.',
	emptyEmailClientInputError: 'Enter your email address to continue.',
	blockedLoginError:
		'This login is blocked due to 10 unsuccessful attempts. We sent an email with more details to the address we have on file.',
	errorUpdatingPassword: 'There was a problem updating your password. Please try again.',
	textshiftTime:
		'Your NetStaff CS Shift has expired and your access privileges have been restricted. Please contact your portal administrator if you believe this is an error.',
	unabledLoginText: 'Please contact your accounting firm for assistance.',
	shiftTimeError:
		'Your NetStaff CS shift has expired and your access privileges have been restricted. Please contact your portal administrator if you believe this is an error.NOACCESS:1',
	emailAlreadyTaken: 'The E-mail you entered is already in use',
	unableToProcessRequest: "We 're encountered an error and were unable to process your request. Please try again later",
	passwordHasBeenUsed: 'The password you entered has been used previously for this login.',
	accountLocked: 'Your account is locked. Please contact your administrator.',
	internalRoutes: [
		'login',
		'time-out',
		'system-down',
		'license-inactive',
		'client-staff/',
		'unable-login/',
		'sign-back-in',
		'login-failure',
		'on-maintenance',
	],
};

export const NetfirmExceptions = {
	InvalidLoginEmailCombinationException:
		'com.thomson.cs.wsd.cws.exceptions.applicationExceptions.InvalidLoginEmailCombinationException',
	BlockedLogin: 'com.thomson.cs.wsd.cws.exceptions.applicationExceptions.BlockedLoginException',
};
