<netfirm-login-card
	[showPrimaryButton]="true"
	[showFirstLink]="false"
	[showSecondaryButton]="false"
	[showOptionDivider]="false"
	primaryButtonText="Sign back in"
	(primaryButtonClick)="goToLogin()"
>
	<div slot="body">
		<div class="NetfirmLogin-timeout--container">
			<h1 class="Netfirm-H1Title">You've been signed out</h1>
			<p class="NetfirmLogin-paragraph">To protect your data, we automatically sign you out due to inactivity.</p>
		</div>
	</div>
</netfirm-login-card>
