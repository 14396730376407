import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Alert } from '../../models/alert.model';

@Injectable({
	providedIn: 'root',
})
export class NetfirmBannerService {
	public notifications$ = new BehaviorSubject<Alert | undefined>(undefined);

	public showNotification(notification: Alert): void {
		this.notifications$.next(notification);
	}

	public getNotification(): Observable<Alert | undefined> {
		return this.notifications$.asObservable();
	}
}
