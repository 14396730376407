<ng-container *ngIf="showClient">
	<netfirm-login-card
		[showPrimaryButton]="true"
		[showFirstLink]="true"
		firstLinktext="{{ 'Netfirm.CiamLogin.AccountUpgradeHelp' | translate }}"
		[showSecondaryButton]="false"
		[showSecondLink]="false"
		[showOptionDivider]="false"
		[showTooltip]="true"
		labeltooltipText="Why do I have to do this?"
		primaryButtonText="{{'Netfirm.CiamLogin.Continue' | translate}}"
		[firstLinkTooltipText]="tooltipText"
		(primaryButtonClick)="createAccount()"
	>
		<div slot="body">
			<div class="NetfirmLogin-client--container">
				<h1>{{'Netfirm.CiamLogin.UpgradeToNewLogin' | translate}}</h1>
				<p class="NetfirmLogin-client-p">{{netFirmLoginClientText}}</p>
			</div>
			<saf-form
				[attr.error-message]="safronFormFields.emailClient.errorMessage"
				[attr.validation-state]="safronFormFields.emailClient.validationState"
				type="email"
				maxLength="64"
				label="{{'Netfirm.CiamLogin.EnterEmailForNewLogin' | translate}}"
				elem-id="emailClient"
				data-qe-id="text-email-Client"
			></saf-form>
		</div>
	</netfirm-login-card>
</ng-container>

<ng-container *ngIf="showStaff">
	<netfirm-login-card
		[showPrimaryButton]="true"
		[showFirstLink]="false"
		firstLinktext="Not sure what to do?"
		[showSecondaryButton]="false"
		[showSecondLink]="false"
		[showOptionDivider]="false"
		[showTooltip]="false"
		primaryButtonText="{{'Netfirm.CiamLogin.Continue' | translate}}"
		[firstLinkTooltipText]="tooltipText"
		(primaryButtonClick)="createAccount()"
	>
		<div slot="body">
			<div class="NetfirmLogin-Staff--container">
				<h1>{{'Netfirm.CiamLogin.NewWayToSignIn' | translate}}</h1>
				<p class="NetfirmLogin-Staff-p">{{'Netfirm.CiamLogin.NewWayToSignIn.Description' | translate}}</p>
				<p class="NetfirmLogin-Staff-p">{{'Netfirm.CiamLogin.DoNotHaveAnAccount' | translate}}</p>
			</div>
			<saf-form
				[attr.error-message]="safronFormFields.emailClient.errorMessage"
				[attr.validation-state]="safronFormFields.emailClient.validationState"
				type="email"
				label="{{'Netfirm.CiamLogin.Email' | translate}}"
				maxLength="64"
				elem-id="emailClient"
				data-qe-id="text-email-Client"
			></saf-form>
		</div>
	</netfirm-login-card>
</ng-container>
