import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NetfirmLoginComponent } from './netfirm-login/netfirm-login.component';
import { NetfirmTimeoutComponent } from './components/netfirm-timeout/netfirm-timeout.component';
import { NetfirmSystemdownComponent } from './components/netfirm-systemdown/netfirm-systemdown.component';
import { NetfirmLoginLicenseInactiveComponent } from './components/netfirm-login-license-inactive/netfirm-login-license-inactive.component';
import { NetfirmClientStaffComponent } from './components/netfirm-client-staff/netfirm-client-staff.component';
import { NetfirmUnableLoginComponent } from './components/netfirm-unable-login/netfirm-unable-login.component';
import { NetfirmSignBackInPageComponent } from './components/netfirm-sign-back-in-page/netfirm-sign-back-in-page.component';
import { NetfirmUnknownLoginFailureComponent } from './components/netfirm-unknown-login-failure/netfirm-unknown-login-failure.component';
import { NetfirmMaintenanceModeComponent } from './components/netfirm-maintenance-mode/netfirm-maintenance-mode.component';

const routes: Routes = [
	{ path: '', redirectTo: '', pathMatch: 'full', component: NetfirmLoginComponent },
	{ path: 'login', component: NetfirmLoginComponent },
	{ path: 'time-out', component: NetfirmTimeoutComponent },
	{ path: 'system-down', component: NetfirmSystemdownComponent },
	{ path: 'license-inactive', component: NetfirmLoginLicenseInactiveComponent },
	{ path: 'client-staff/:parameter', component: NetfirmClientStaffComponent },
	{ path: 'unable-login/:parameter', component: NetfirmUnableLoginComponent },
	{ path: 'sign-back-in', component: NetfirmSignBackInPageComponent },
	{ path: 'login-failure', component: NetfirmUnknownLoginFailureComponent },
	{ path: 'on-maintenance', component: NetfirmMaintenanceModeComponent },
	{ path: '**', redirectTo: '' },
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
