import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'netfirm-login-timeout',
	templateUrl: './netfirm-timeout.component.html',
	styleUrls: ['./netfirm-timeout.component.scss'],
})
export class NetfirmTimeoutComponent {
	public constructor(private readonly router: Router) {}
	public goToLogin(): void {
		// Return to the login
		this.router.navigate(['']).finally(() => void {});
	}
}
