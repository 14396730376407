import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NetfirmBannerService } from './netfirm-banner.service';
import { map } from 'rxjs/operators';
import { Alert, AlertType } from '../../models/alert.model';

@Component({
	selector: 'netfirm-login-banner',
	templateUrl: './netfirm-banner.component.html',
	styleUrls: ['./netfirm-banner.component.scss'],
})
export class NetfirmBannerComponent implements OnInit, OnDestroy {
	@Input() public type: AlertType = AlertType.INFORMATION;
	@Input() public isToast = true;

	@Input() public dismissable = true;
	@Input() public title?: string;
	@Input() public message = '';
	public AlertType = AlertType;
	public alert$!: Observable<Alert | undefined>;
	public subscription = new Subscription();
	public isAlertHidden = false;
	public hideToast = true;
	public constructor(private readonly bannerService: NetfirmBannerService) {}

	public ngOnInit(): void {
		if (this.isToast) {
			// This variable assignment is used for unit test purposes
			this.alert$ = this.bannerService.getNotification().pipe(
				map((notification: Alert | undefined) => {
					if (notification) {
						this.message = notification.message ?? '';
						this.type = notification.type;
						this.hideToast = false;
					}

					setTimeout(() => {
						this.hideToastAlert();
					}, 10000);
					return notification;
				}),
			);
			this.subscription.add(this.alert$.subscribe());
		} else {
			// This should be set as false for toast and inline alerts, otherwise the banner will be hidden
			this.hideToast = false;
		}
	}

	public hideAlert(): void {
		this.isAlertHidden = true;
	}

	public hideToastAlert(): void {
		this.hideToast = true;
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
