<netfirm-login-card
	[showPrimaryButton]="true"
	primaryButtonText="{{'Netfirm.CiamLogin.ReturnToSignIn' | translate}}"
	[showFirstLink]="false"
	[showSecondLink]="false"
	[showSecondaryButton]="false"
	[showOptionDivider]="false"
	(primaryButtonClick)="goToLogin()"
>
	<div slot="body">
		<div style="text-align: center">
			<svg width="62" height="58" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7 33V5C7 2.791 8.791 1 11 1H51C53.209 1 55 2.791 55 5V23"
					stroke="#404040"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M29 7H33" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M34 39H1C1 43.418 4.582 47 9 47H34"
					stroke="#404040"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<circle cx="51" cy="50" r="2" fill="#FA6400" />
				<rect
					x="41"
					y="43"
					width="20"
					height="14"
					stroke="#FA6400"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M45 43V35C45 31.686 47.686 29 51 29V29C54.314 29 57 31.686 57 35V43"
					stroke="#FA6400"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
		<div class="NetfirmLogin-LoginFailure--container">
			<h1 class="Netfirm-H1Title">{{'Netfirm.CiamLogin.ProblemSigningIn' | translate}}</h1>
			<!--<p class="NetfirmLogin-paragraph">Contact your Netfirm CS administrator</p>-->
		</div>
		<div id="text" class="Netfirm-Solution">
			<div class="Netfirm-column1">
				<h2 class="Netfirm-subtitle">{{'Netfirm.CiamLogin.MayNeedToUpgrade' | translate}}</h2>
				<p>{{'Netfirm.CiamLogin.CompleteProcessOfUpgrade' | translate}}</p>
				<ol class="Netfirm-list">
					<li>{{'Netfirm.CiamLogin.ReturnToSignIn' | translate}}</li>
					<li>{{'Netfirm.CiamLogin.EnterLoginAndPassword' | translate}}</li>
					<li>{{'Netfirm.CiamLogin.Click' | translate}} <b>{{ 'Netfirm.CiamLogin.UpgradeAccount' | translate }}</b></li>
					<li>{{'Netfirm.CiamLogin.FollowStepsToComplete' | translate}}</li>
				</ol>
			</div>
			<div class="Netfirm-column2">
				<h2 class="Netfirm-subtitle">{{'Netfirm.CiamLogin.OrSignOutOtherAccounts' | translate}}</h2>
				<p>{{'Netfirm.CiamLogin.OtherAccountsWarning' | translate}}</p>
				<div>
					<ol class="Netfirm-list">
						<li>
							<saf-link semantic id="linklogout" tabindex="0">
								<a (click)="logout()">{{'Netfirm.CiamLogin.SignOutAllTRAccounts' | translate}}</a>
								<saf-icon
									role="img"
									aria-label="Opens new tab"
									class="NetfirmLogin-Login-newTabIcon"
									size="16"
									icon-data="<svg alt='Opens new tab' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path d='M15.9615 0.30908C15.9109 0.18664 15.8134 0.08911 15.6909 0.03845C15.6298 0.01318 15.5651 0 15.5 0H8.5C8.22363 0 8 0.22363 8 0.5C8 0.77637 8.22363 1 8.5 1H14.293L7.14648 8.14648C6.95117 8.34179 6.95117 8.6582 7.14648 8.85351C7.24414 8.95117 7.37208 8.99999 7.50001 8.99999C7.62794 8.99999 7.75586 8.95116 7.85352 8.85351L15 1.70703V7.5C15 7.77637 15.2236 8 15.5 8C15.7764 8 16 7.77637 16 7.5V0.5C16 0.43494 15.9868 0.37018 15.9615 0.30908Z' fill='#005DA2'/>
									<path d='M12.5 9C12.2236 9 12 9.22363 12 9.5V14C12 14.5518 11.5518 15 11 15H2C1.44824 15 1 14.5518 1 14V5C1 4.44824 1.44824 4 2 4H6.5C6.77637 4 7 3.77637 7 3.5C7 3.22363 6.77637 3 6.5 3H2C0.89746 3 0 3.89746 0 5V14C0 15.1025 0.89746 16 2 16H11C12.1025 16 13 15.1025 13 14V9.5C13 9.22363 12.7764 9 12.5 9Z' fill='#005DA2'/>
									</svg>"
								></saf-icon>
							</saf-link>
						</li>
						<li>{{'Netfirm.CiamLogin.ReturnToSignInAndTryAgain' | translate}}</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
	<div slot="form-finalText">
		<p class="Netfirm-finalText">{{'Netfirm.CiamLogin.StillStuck' | translate}}</p>
	</div>
</netfirm-login-card>
