import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NetfirmLoginConstants } from '../models/netfirm-login-constants.model';
import { NetfirmLoginService } from '../services/netfirm-login.service';
import { Observable, Subscription } from 'rxjs';
import { FirmInformation } from '../models/firm-information.model';
import { ModalSize } from '../enums/modal-size.enum';
import { ModalIdentifier } from '../enums/modal-identifier.enum';
import { DwrService } from '../services/dwr.service';
import { NetfirmBannerService } from '../shared/netfirm-banner/netfirm-banner.service';
import { AlertType } from '../models/alert.model';
import { DWRResponseType } from '../enums/dwr-response.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LoginResponse } from '../models/login.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'netfirm-login',
	templateUrl: './netfirm-login.component.html',
	styleUrls: ['./netfirm-login.component.scss'],
})
export class NetfirmLoginComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	public viewSystemStatusLink = NetfirmLoginConstants.viewSystemStatusLink;
	public firmId!: string;
	public firmInformation$!: Observable<FirmInformation>;
	public openForgotPasswordModal = false;
	public openResetPasswordModal = false;
	public openSecurityQuestionsModal = false;
	public ModalSize = ModalSize;
	public NetfirmLoginConstants = NetfirmLoginConstants;
	public ModalIdentifier = ModalIdentifier;
	public resetPasswordViaEmailError!: string;
	public subscription = new Subscription();
	public loginName!: string;
	public oldPassword!: string;
	public passwordMeetRequirements = false;
	public isRootPath = true;
	public isMigrated = false;
	public showFormDivider = true;
	public showSecondaryButton = true;
	public showFirstLinkBind = true;
	public forgotPasswordFields = {
		loginReset: '',
		emailReset: '',
	};

	public resetPasswordFields = {
		newPassword: '',
		confirmPassword: '',
	};

	public inlineErrorMessage = '';
	public displayInlineError = false;
	public ariaRoleChanged = false;
	public AlertType = AlertType;
	public firmInfo: { text1: string; text2: string; text3: string; logoUrl: string } = {
		text1: '',
		text2: '',
		text3: '',
		logoUrl: '',
	};

	public safronFormFields = {
		mainLoginName: {
			validationState: 'default',
			errorMessage: '',
		},
		mainPassword: {
			validationState: 'default',
			errorMessage: '',
		},
		loginReset: {
			validationState: 'default',
			errorMessage: '',
		},
		emailReset: {
			validationState: 'default',
			errorMessage: '',
		},
		newPassword: {
			validationState: 'default',
			errorMessage: '',
		},
		confirmPassword: {
			validationState: 'default',
			errorMessage: '',
		},
	};

	public passwordRequirements = {
		uppercaseLetter: false,
		lowercaseLetter: false,
		numericValue: false,
		specialCharacter: false,
	};

	@ViewChild('forgotPasswordModal', { static: false }) public forgotPasswordModal!: ElementRef;
	@ViewChild('resetPasswordModal', { static: false }) public resetPasswordModal!: ElementRef;

	public constructor(
		private readonly loginService: NetfirmLoginService,
		private readonly dwrService: DwrService,
		private readonly alertService: NetfirmBannerService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly cookieService: CookieService,
		private readonly translateService: TranslateService,
	) {}

	public ngOnInit(): void {
		this.isMigrated = Boolean(this.cookieService.get('isMigrated'));

		if (this.router.url === '/login') {
			this.isRootPath = false;
			this.showFormDivider = false;
			this.showSecondaryButton = false;
		}

		this.route.queryParams.subscribe(params => {
			if (params?.firm) {
				this.cookieService.set('firmID', params?.firm);

				// I added this part of the code here in order to update the Firm logo when firmId changes in the url
				const cookieFirmIdInOBSParams = this.cookieService.get('firmID');
				if (cookieFirmIdInOBSParams) {
					this.updateInformation(cookieFirmIdInOBSParams);
				}
			} else {
				// When user comes from logout the params?.firm does not detect any parameters, because of hash.
				// So we use URLSearchParams obj to get the URL
				const cookieFirmId = this.cookieService.get('firmID');
				const queryParams = new URLSearchParams(window.location.search);
				const urlFirmId = queryParams.get('firm') ?? '';

				if (!urlFirmId) {
					this.updateInformation(cookieFirmId);
				} else if (cookieFirmId !== urlFirmId) {
					this.updateInformation(urlFirmId);
					this.cookieService.set('firmID', urlFirmId);
				}
			}
		});
	}

	public ngAfterViewInit(): void {
		this.closeModal = this.closeModal.bind(this);
		this.forgotPasswordModal?.nativeElement?.addEventListener('closemodal', this.closeModal);
		this.validatePasswordRequirements = this.validatePasswordRequirements.bind(this);
		document.getElementById('saf-newPassword')?.addEventListener('safInput', this.validatePasswordRequirements);
		document
			.getElementById('saf-confirmNewPassword')
			?.addEventListener('safChange', event => this.updateSafronFields(event, 'confirmNewPassword'));
		document
			.getElementById('saf-loginReset')
			?.addEventListener('safChange', event => this.updateSafronFields(event, 'loginReset'));
		document
			.getElementById('saf-emailReset')
			?.addEventListener('safChange', event => this.updateSafronFields(event, 'emailReset'));
	}

	public ngAfterViewChecked(): void {
		const inputErrors = document.querySelectorAll('.saf-form--feedback');

		if (inputErrors && inputErrors.length > 0 && !this.ariaRoleChanged) {
			inputErrors.forEach(input => {
				input.setAttribute('aria-live', 'polite');
			});
			this.ariaRoleChanged = true;
		}

		// Reset password, new password
		document
			.querySelector('#newPassword + button')
			?.querySelector('.saf-svg > :first-child')
			?.setAttribute('aria-label', 'Hide password');

		document.querySelector('#linkViewSystem')?.parentElement?.setAttribute('alt', 'Opens new tab');
		document.querySelector('#linkNotSureWhatToDo')?.parentElement?.setAttribute('alt', 'Opens new tab');
		document.querySelector('#userPassword + button')?.setAttribute('title', 'Show password');
	}

	public logInToNetfirm(): void {
		if (this.isRootPath && this.isMigrated) {
			void this.router.navigate(['/login']);
		} else {
			const username: string = (document.getElementById('userLogin') as any).value.replace('+', '%2b');
			const password: string = (document.getElementById('userPassword') as any).value;
			if (this.loginFieldValidation(username, password)) {
				this.subscription.add(
					this.loginService.loginToNetfirm(username, password).subscribe({
						next: (response: LoginResponse) => {
							if (response.redirectTo === '/auth') {
								window.location.href = '/nextgen/auth';
								return;
							}

							if (response.redirectTo) {
								void this.router
									.navigate([response.redirectTo.slice(2)], {
										relativeTo: this.route,
										state: { firmID: this.firmInfo.text1 },
										queryParams: { firmID: this.firmInfo.text1 },
									})
									.then(() => {
										window.location.reload();
									});
							}
						},
						// For the successful case a redirection should be triggered from server side
						error: (errorResponse: HttpErrorResponse) => {
							if (errorResponse.status === 401) {
								if (errorResponse.error === NetfirmLoginConstants.resetPasswordError) {
									this.loginName = username;
									this.oldPassword = password;
									this.openResetPasswordModal = true;
								} else if (errorResponse.error === NetfirmLoginConstants.invalidLoginOrPassword) {
									this.safronFormFields.mainPassword.validationState = 'error';
									this.safronFormFields.mainPassword.errorMessage = this.translateService.instant(
										'Netfirm.CiamLogin.UnrecognizedPassword',
									);
								}
							} else if (errorResponse.status === 403) {
								if (errorResponse.error === NetfirmLoginConstants.accountLocked) {
									this.safronFormFields.mainPassword.validationState = 'error';
									this.safronFormFields.mainPassword.errorMessage = NetfirmLoginConstants.accountLocked;
								}
							}
						},
					}),
				);
			}
		}
	}

	public resetPasswordViaEmail(): void {
		this.clearInputErrors();
		const loginReset: string = this.forgotPasswordFields.loginReset;
		const emailReset: string = this.forgotPasswordFields.emailReset;
		let validEmail = false;
		if (emailReset && NetfirmLoginConstants.mailFormat.exec(emailReset)) {
			validEmail = true;
		}

		if (loginReset && validEmail) {
			this.subscription.add(
				this.dwrService.emailNewPassword(loginReset, emailReset).subscribe((response: DWRResponseType) => {
					if (response === DWRResponseType.Success) {
						this.openForgotPasswordModal = false;
						this.alertService.showNotification({
							type: AlertType.SUCCESS,
							dismissable: true,
							message: this.translateService.instant('Netfirm.CiamLogin.TemporaryPasswordMessage'),
						});
						document.getElementById('divToast')?.focus();
					} else if (response === DWRResponseType.BadCombination) {
						this.displayInlineError = true;
						this.inlineErrorMessage = this.translateService.instant('Netfirm.CiamLogin.InvalidLoginCombination');
					} else if (response === DWRResponseType.TooManyAttempts) {
						this.displayInlineError = true;
						this.inlineErrorMessage = NetfirmLoginConstants.blockedLoginError;
					}
				}),
			);
		} else {
			if (!loginReset) {
				this.safronFormFields.loginReset.validationState = 'error';
				this.safronFormFields.loginReset.errorMessage = NetfirmLoginConstants.loginNameEmptyInputError;
				document.getElementById('loginReset')?.focus();
			}

			if (!validEmail && emailReset) {
				this.safronFormFields.emailReset.validationState = 'error';
				this.safronFormFields.emailReset.errorMessage = NetfirmLoginConstants.wrongEmailFormat;
			}

			if (!emailReset) {
				this.safronFormFields.emailReset.validationState = 'error';
				this.safronFormFields.emailReset.errorMessage = NetfirmLoginConstants.emptyEmailInputError;
			}
		}
	}

	public resetPassword(): void {
		this.clearInputErrors();
		const newPassword: string = this.resetPasswordFields.newPassword;
		const confirmNewPassword: string = this.resetPasswordFields.confirmPassword;
		if (this.passwordRequirements && newPassword && confirmNewPassword && newPassword === confirmNewPassword) {
			this.loginService.resetPassword(this.loginName, this.oldPassword, confirmNewPassword).subscribe(
				() => {
					this.openResetPasswordModal = false;
					this.alertService.showNotification({
						type: AlertType.SUCCESS,
						dismissable: true,
						message: this.translateService.instant('Netfirm.CiamLogin.PasswordUpdated'),
					});
				},
				// Error handler
				error => {
					this.openResetPasswordModal = false;
					let msgerror = NetfirmLoginConstants.errorUpdatingPassword;
					if (error.error.Message === NetfirmLoginConstants.passwordHasBeenUsed) {
						msgerror = NetfirmLoginConstants.passwordHasBeenUsed;
					}

					this.alertService.showNotification({
						type: AlertType.ERROR,
						dismissable: true,
						message: msgerror,
					});
				},
			);
		} else if (!newPassword || !confirmNewPassword) {
			this.resetPasswordViaEmailError = NetfirmLoginConstants.newAndConfirmPasswordRequired;
			document.getElementById('newPassword')?.focus();
		} else if (newPassword !== confirmNewPassword) {
			this.resetPasswordViaEmailError = NetfirmLoginConstants.passwordsMustMatch;
		}
	}

	public openModal(modalName: string): void {
		if (modalName === ModalIdentifier.ForgotPassword) {
			this.openForgotPasswordModal = true;
		} else if (modalName === ModalIdentifier.ResetPassword) {
			this.openResetPasswordModal = true;
		} else if (modalName === ModalIdentifier.SecurityQuestions) {
			this.openSecurityQuestionsModal = true;
		}
	}

	public closeModal(modalName: string): void {
		if (modalName === ModalIdentifier.ForgotPassword) {
			setTimeout(function () {
				document.getElementById('linkForgotPassword')?.focus();
			}, 300);
			this.openForgotPasswordModal = false;
			this.displayInlineError = false;
		} else if (modalName === ModalIdentifier.ResetPassword) {
			this.openResetPasswordModal = false;
		} else if (modalName === ModalIdentifier.SecurityQuestions) {
			this.openSecurityQuestionsModal = false;
		}
	}

	public clearInputErrors(): void {
		for (const fields of Object.values(this.safronFormFields)) {
			fields.errorMessage = '';
			fields.validationState = 'default';
		}

		this.resetPasswordViaEmailError = '';
		this.inlineErrorMessage = '';
		this.displayInlineError = false;
	}

	public validatePasswordRequirements(event: Event): void {
		const passwordValue = (event as any).detail.target.value;

		this.passwordRequirements.uppercaseLetter = /[A-Z]/.test(passwordValue);
		this.passwordRequirements.lowercaseLetter = /[a-z]/.test(passwordValue);
		this.passwordRequirements.numericValue = /[0-9]/.test(passwordValue);
		// eslint-disable-next-line
		this.passwordRequirements.specialCharacter = /[\[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\]]/.test(passwordValue);

		const meetRequirementsList = Object.values(this.passwordRequirements).filter(requirement => requirement);
		this.passwordMeetRequirements = meetRequirementsList.length === 4 && passwordValue.length >= 8;
		if (this.passwordMeetRequirements) {
			this.resetPasswordFields.newPassword = passwordValue;
			this.safronFormFields.newPassword.validationState = 'default';
			this.safronFormFields.newPassword.errorMessage = '';
		} else {
			this.safronFormFields.newPassword.validationState = 'error';
			this.safronFormFields.newPassword.errorMessage = NetfirmLoginConstants.passwordDoesNotMeetRequirements;
		}
	}

	public existingFirmEnter($event: Event): void {
		const username: string = (document.getElementById('userLogin') as any).value.replace('+', '%2b');
		const password: string = (document.getElementById('userPassword') as any).value;
		if (($event as any).key === 'Enter' && this.loginFieldValidation(username, password)) {
			this.logInToNetfirm();
		}
	}

	public forgotPasswordEnter($event: Event): void {
		if (this.isEnterKey($event)) {
			this.openModal(ModalIdentifier.ForgotPassword);
		}
	}

	public resetPasswordViaEmailEnter($event: Event): void {
		if (this.isEnterKey($event)) {
			this.resetPasswordViaEmail();
		}
	}

	public resetPasswordEnterEnter($event: Event): void {
		if (this.isEnterKey($event)) {
			this.resetPassword();
		}
	}

	public isEnterKey($event: Event): boolean {
		return ($event as any).key === 'Enter';
	}

	public updateSafronFields($event: Event, fieldName: string): void {
		if (fieldName === 'loginReset') {
			this.forgotPasswordFields.loginReset = ($event as any).detail.target.value;
		} else if (fieldName === 'emailReset') {
			this.forgotPasswordFields.emailReset = ($event as any).detail.target.value;
		} else if (fieldName === 'confirmNewPassword') {
			this.resetPasswordFields.confirmPassword = ($event as any).detail.target.value;
		}
	}

	public loginFieldValidation(username: string, password: string): boolean {
		this.clearInputErrors();
		if (username && password) {
			return true;
		}

		if (!username) {
			this.safronFormFields.mainLoginName.validationState = 'error';
			this.safronFormFields.mainLoginName.errorMessage = NetfirmLoginConstants.loginNameEmptyInputError;
			document.getElementById('userLogin')?.focus();
		}

		if (!password) {
			this.safronFormFields.mainPassword.validationState = 'error';
			this.safronFormFields.mainPassword.errorMessage = NetfirmLoginConstants.emptyPasswordInputError;
			document.getElementById('userPassword')?.focus();
		}

		return false;
	}

	public updateInformation(cookieId: string): void {
		this.subscription.add(
			this.loginService.getFirmInformation(cookieId).subscribe((firmInformation: FirmInformation) => {
				this.firmInfo = {
					logoUrl: firmInformation.logoUrl,
					text1: firmInformation.mobileText1,
					text2: firmInformation.mobileText2,
					text3: firmInformation.mobileText3,
				};
			}),
		);
	}

	public redirectToAuth0(): void {
		window.location.href = '/nextgen/auth';
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
