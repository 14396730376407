<netfirm-login-card
	[showPrimaryButton]="false"
	[showFirstLink]="false"
	[showSecondaryButton]="false"
	[showOptionDivider]="false"
>
	<div slot="body">
		<div style="text-align: center">
			<saf-icon
				size="64"
				icon-data="<svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M8 37V9C8 6.791 9.791 5 12 5H52C54.209 5 56 6.791 56 9V27' stroke='#404040' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
				<path d='M30 11H34' stroke='#404040' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
				<path d='M35 43H2C2 47.418 5.582 51 10 51H35' stroke='#404040' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
				<circle cx='52' cy='54' r='2' fill='#FA6400'/>
				<rect x='42' y='47' width='20' height='14' stroke='#FA6400' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
				<path d='M46 47V39C46 35.686 48.686 33 52 33V33C55.314 33 58 35.686 58 39V47' stroke='#FA6400' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
				</svg>
			"
			>
			</saf-icon>
		</div>
		<div class="NetfirmLogin-licenseInactive--container">
			<h1>Your firm's license is inactive</h1>
			<p>Contact your firm administrator.</p>
		</div>
	</div>
</netfirm-login-card>
