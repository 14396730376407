export enum AlertType {
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS',
	INFORMATION = 'INFORMATION',
}

export interface Alert {
	type: AlertType;
	inline?: boolean;
	link?: string;
	dismissable?: boolean;
	title?: string;
	message?: string;
}
