<netfirm-login-card
	[showPrimaryButton]="false"
	[showSecondaryButton]="false"
	[showFirstLink]="false"
	[showOptionDivider]="false"
	primaryButtonText="Refresh page"
>
	<div slot="body">
		<div style="text-align: center">
			<svg width="62" height="58" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7 33V5C7 2.791 8.791 1 11 1H51C53.209 1 55 2.791 55 5V23"
					stroke="#404040"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M29 7H33" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M34 39H1C1 43.418 4.582 47 9 47H34"
					stroke="#404040"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<circle cx="51" cy="50" r="2" fill="#FA6400" />
				<rect
					x="41"
					y="43"
					width="20"
					height="14"
					stroke="#FA6400"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M45 43V35C45 31.686 47.686 29 51 29V29C54.314 29 57 31.686 57 35V43"
					stroke="#FA6400"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
		<h1 class="NetfirmLogin-title">Unable to log in</h1>
		<p *ngIf="shiftTimeValid" class="NetfirmLogin-contact">Please contact your accounting firm for assistance.</p>
		<p *ngIf="!shiftTimeValid" class="NetfirmLogin-shiftTime">{{shiftTimeText}}</p>
	</div>
</netfirm-login-card>
