<div
	id="divToast"
	aria-live="success"
	[attr.role]="type === AlertType.ERROR? 'alert': 'status'"
	class="NetfirmLogin-notificationContainer NetfirmLogin-successBorder"
	tabindex="0"
	[ngClass]="{
		'NetfirmLogin-notificationDisplay': !isAlertHidden,
		'NetfirmLogin-notificationHide': isAlertHidden,
		'NetfirmLogin-toastNotification': isToast,
		'NetfirmLogin-notification--hideToast': hideToast,
		'NetfirmLogin-successBorder': type === AlertType.SUCCESS,
		'NetfirmLogin-informationBorder': type === AlertType.INFORMATION,
		'NetfirmLogin-warningBorder': type === AlertType.WARNING,
		'NetfirmLogin-errorBorder': type === AlertType.ERROR
	}"
	*ngIf="!hideToast && !isAlertHidden"
>
	<div class="NetfirmLogin-notificationIcon" [ngClass]="'NetfirmLogin-' + type.toLowerCase()">
		<ng-container *ngIf="type === AlertType.SUCCESS">
			<saf-icon
				role="img"
				aria-label="Success"
				icon-data="<svg role='img' aria-label='Success' width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M21.4832 8.16667L23.1332 9.81658L12.1499 20.7998L5.83333 14.4832L7.48325 12.8333L12.1499 17.5L21.4832 8.16667Z' fill='white'/>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 25.6667C20.4433 25.6667 25.6667 20.4433 25.6667 14C25.6667 7.55668 20.4433 2.33333 14 2.33333C7.55668 2.33333 2.33333 7.55668 2.33333 14C2.33333 20.4433 7.55668 25.6667 14 25.6667Z' fill='white'/>
							</svg>"
			></saf-icon>
		</ng-container>

		<ng-container *ngIf="type === AlertType.INFORMATION">
			<saf-icon
				role="img"
				aria-label="Information"
				icon-data="<svg role='img' aria-label='Information' width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 25.6667C20.4433 25.6667 25.6667 20.4433 25.6667 14C25.6667 7.55668 20.4433 2.33333 14 2.33333C7.55668 2.33333 2.33333 7.55668 2.33333 14C2.33333 20.4433 7.55668 25.6667 14 25.6667Z' fill='white'/>
							<path d='M15.1667 21H12.8333L12.8333 11.6667H15.1667L15.1667 21Z' fill='white'/>
							<path d='M14 7C14.6443 7 15.1667 7.52233 15.1667 8.16667C15.1667 8.811 14.6443 9.33333 14 9.33333C13.3557 9.33333 12.8333 8.811 12.8333 8.16667C12.8333 7.52233 13.3557 7 14 7Z' fill='white'/>
							</svg>"
			></saf-icon>
		</ng-container>

		<ng-container *ngIf="type === AlertType.WARNING">
			<saf-icon
				role="img"
				aria-label="Warning"
				icon-data="<svg role='img' aria-label='Warning' width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M12.418 1.07977C12.7267 0.539889 13.3762 0.166748 14 0.166748C14.6238 0.166748 15.2733 0.539889 15.5825 1.08064L27.7712 22.4421C28.0763 22.9771 28.0765 23.7063 27.7704 24.2413C27.4071 24.876 27.0447 25.1683 26.3478 25.1667H1.65214C0.954756 25.1667 0.592195 24.8748 0.229602 24.2413C-0.0765341 23.7063 -0.0763063 22.9771 0.22892 22.4419L12.418 1.07977ZM25.7829 23.2437L13.8564 2.41033L1.93104 23.2437H25.7829Z' fill='#404040'/>
							<path d='M13 8.16675H15V17.1668H13V8.16675Z' fill='#404040'/>
							<path d='M14 21.1668C13.4477 21.1668 13 20.719 13 20.1668C13 19.6145 13.4477 19.1668 14 19.1668C14.5523 19.1668 15 19.6145 15 20.1668C15 20.719 14.5523 21.1668 14 21.1668Z' fill='#404040'/>
							</svg>"
			></saf-icon>
		</ng-container>

		<ng-container *ngIf="type === AlertType.ERROR">
			<saf-icon
				role="img"
				aria-label="Error"
				icon-data="<svg role='img' aria-label='Error' width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 25.6667C20.4433 25.6667 25.6667 20.4433 25.6667 14C25.6667 7.55668 20.4433 2.33333 14 2.33333C7.55668 2.33333 2.33333 7.55668 2.33333 14C2.33333 20.4433 7.55668 25.6667 14 25.6667Z' fill='white'/>
							<path d='M12.8333 7H15.1667V16.3333H12.8333V7Z' fill='white'/>
							<path d='M14 21C13.3557 21 12.8333 20.4777 12.8333 19.8333C12.8333 19.189 13.3557 18.6667 14 18.6667C14.6443 18.6667 15.1667 19.189 15.1667 19.8333C15.1667 20.4777 14.6443 21 14 21Z' fill='white'/>
							</svg>"
			></saf-icon>
		</ng-container>
	</div>

	<div class="NetfirmLogin-notificationContent" [ngClass]="isToast ? 'NetfirmLogin-' + type.toLowerCase() : ''">
		<p
			class="NetfirmLogin-notificationContent--text"
			[ngClass]="(AlertType.ERROR && !isToast)? 'NetfirmLogin-notificationContent--textError': ''"
		>
			{{ message }}
		</p>

		<ng-container *ngIf="dismissable">
			<saf-button
				[attr.aria-label]="'Dismiss'"
				type="button"
				size="small"
				class="NetfirmLogin-notification--dismissButton"
				variant="ghost"
				(click)="isToast ? hideToastAlert() : hideAlert()"
			>
				<ng-container *ngIf="dismissable">
					<ng-container *ngIf="type !== AlertType.WARNING; else greyIconTemplate">
						<saf-icon
							size="16"
							aria-hidden="true"
							icon-data="<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95118 13.6583 1.95118 13.3417 2.14645 13.1464L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645Z' fill='white'/>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.6583 14.0488 13.3417 14.0488 13.1464 13.8536L2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645Z' fill='white'/>
							</svg>"
						>
						</saf-icon>
					</ng-container>
					<ng-template #greyIconTemplate>
						<saf-icon
							size="16"
							aria-hidden="true"
							icon-data="<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95118 13.6583 1.95118 13.3417 2.14645 13.1464L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645Z' fill='#404040'/>
							<path fill-rule='evenodd' clip-rule='evenodd' d='M2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.6583 14.0488 13.3417 14.0488 13.1464 13.8536L2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645Z' fill='#404040'/>
							</svg>"
						>
						</saf-icon>
					</ng-template>
				</ng-container>
			</saf-button>
		</ng-container>
	</div>
</div>
