import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NetfirmLoginConstants } from '../../models/netfirm-login-constants.model';
import { NetfirmBannerService } from '../../shared/netfirm-banner/netfirm-banner.service';
import { AlertType } from '../../models/alert.model';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'netfirm-login-client-staff',
	templateUrl: './netfirm-client-staff.component.html',
	styleUrls: ['./netfirm-client-staff.component.scss'],
})
export class NetfirmClientStaffComponent implements OnInit, AfterViewChecked {
	public userType!: string;
	public firmAccount!: string;
	public tooltipText!: string;
	public showClient = false;
	public showStaff = false;
	public netFirmLoginClientText!: string;
	public ariaRoleChanged = false;

	public safronFormFields = {
		emailClient: {
			validationState: 'default',
			errorMessage: '',
		},
	};

	public constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly alertService: NetfirmBannerService,
		private readonly title: Title,
		private readonly translateService: TranslateService,
	) {
		const firmId = this.router.getCurrentNavigation()?.extras.state?.firmID;
		if (firmId !== null) {
			this.firmAccount = firmId;
		}

		this.title.setTitle('Client Staff');
	}

	public ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params?.error) {
				let errorMessage = '';
				if (params.error === '1') {
					errorMessage = NetfirmLoginConstants.emailAlreadyTaken;
				} else if (params.error === '2') {
					errorMessage = NetfirmLoginConstants.unableToProcessRequest;
				}

				this.alertService.showNotification({
					type: AlertType.ERROR,
					message: errorMessage,
					dismissable: true,
				});
			}

			if (params?.firmID) {
				this.firmAccount = params?.firmID;
			}

			this.title.setTitle('Client Staff');
		});
		this.userType = this.route.snapshot.params.parameter;
		this.showClient = true;

		this.translateService
			.get(['Netfirm.CiamLogin.ReasonToUpgrade', 'Netfirm.CiamLogin.ToAcessYourAccountCreateNewLogin'], {
				firmAccount: this.firmAccount,
			})
			.subscribe(translations => {
				this.tooltipText = translations['Netfirm.CiamLogin.ReasonToUpgrade'];
				this.netFirmLoginClientText = translations['Netfirm.CiamLogin.ToAcessYourAccountCreateNewLogin'];
			});
	}

	public ngAfterViewChecked(): void {
		const inputErrors = document.querySelectorAll('.saf-form--feedback');

		if (inputErrors && inputErrors.length > 0 && !this.ariaRoleChanged) {
			inputErrors.forEach(input => {
				input.setAttribute('aria-live', 'polite');
			});
			this.ariaRoleChanged = true;
		}
	}

	public createAccount(): void {
		let emailClient: string = (document.getElementById('emailClient') as any).value;
		let validEmail = false;
		if (emailClient && NetfirmLoginConstants.mailFormat.exec(emailClient)) {
			validEmail = true;
		}

		if (emailClient && validEmail) {
			// Redirection is handled on server side, if the email is already in use
			// server should cyrcle back to this screen or send a message to communicate it
			emailClient = emailClient.replace('+', '%2b');
			window.location.href = `/nextgen/auth-email?registration=${emailClient}`;
		} else {
			if (!validEmail && emailClient) {
				this.safronFormFields.emailClient.validationState = 'error';
				this.safronFormFields.emailClient.errorMessage = this.translateService.instant('Netfirm.CiamLogin.IncorrectEmail');
			}

			if (!emailClient) {
				this.safronFormFields.emailClient.validationState = 'error';
				this.safronFormFields.emailClient.errorMessage = NetfirmLoginConstants.emptyEmailClientInputError;
			}
		}
	}
}
